<template>
    <div class="container">

        <div class="panel">
            <div class="two">
                <Ad />
            </div>
            <Alert />
            <div class="two">
                <Category :list="category" />
            </div>
                <List :list="list" />
        </div>

    </div>
</template>

<script>
import Alert from '@/components/alert'
import Ad from './components/ad'
import Category from './components/category'
import List from './components/list'

import { fetchList } from '@/api/article'

export default {
    components: {
        Alert,
        Category,
        Ad,
        List,
    },

    data() {
        return {
            category: [],
            list: []
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "hyfwdz",
                    page: 1,
                    rows: 6,
                },
                    {
                        category: "qhgk",
                        page: 1,
                        rows: 10,
                    },
                ]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.category = res[0].data.list;
                    }
                    if (res[1].data && Array.isArray(res[1].data.list)) {
                        this.list = res[1].data.list;
                    }
                }
            })
        }
    }

}
</script>

<style lang="less" scoped>
    .two {
        grid-column-start: 1;
        grid-column-end: 3;
    }
</style>