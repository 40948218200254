<template>

                 <Card title="行业服务定制" :extra="false">
                     
        <div class="category">
            <div class="clickable" v-for="(item,index) in list" :key="index" @click="openArticleDetail(item)">
                <img :src="item.face_img" alt="">
            </div>
        </div>
            </Card>

</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            // list: [
                // require('@/assets/major1.png'),
                // require('@/assets/major2.png'),
                // require('@/assets/major3.png'),
                // require('@/assets/major4.png'),
                // require('@/assets/major5.png'),
                // require('@/assets/major6.png'),
            // ]
            
        }
    },
    methods: {
        onChange(page) {
            console.log(page)
        },
    }
}
</script>

<style lang="less" scoped>

.category {
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 12px;

    img {
        width: 100%;
    }
}
.pagination {
    margin: 24px 0;
}
</style>