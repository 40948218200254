<template>
  <img style="width: 100%; height: 100%" :src="icon" alt="" />
</template>
<script>
export default {
  data() {
    return {
      icon: require("@/assets/app.png"),
    };
  },
};
</script>