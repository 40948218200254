<template>
    <Card title="气候概况" url='/article-category?name=专业服务'>
        <div class="list">
            <a href="#" v-for="item in list" :key="item.code" class="item" :title="item.title"
                @click="openArticleDetail(item)">
                {{item.title}}
            </a>
        </div>
    </Card>

</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }

}
</script>

<style lang="less" scoped>
.list {
    display: flex;
    flex-direction: column;
    .item {
        margin-bottom: 12px;
        position: relative;
        left: 12px;

        &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: -12px;
            width: 5px;
            height: 5px;
            background-color: #999;
            border-radius: 50%;
        }
    }
}
</style>